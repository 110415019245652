import { atom } from 'jotai';
import type { TimeDefinition } from '~/schemas/enums';

export interface UserConfig {
  countryCode: string;
  isEndConsumer: boolean;
  logoUrl: string | undefined;
  orgName: string;
  timezone: string;
  timeDefinitions: TimeDefinition[];
  welcomeScreenEnabled: boolean;
  hideProductionDevicesData: boolean;
}

export const userConfig = atom<UserConfig>({
  countryCode: 'GB',
  isEndConsumer: false,
  logoUrl: undefined,
  orgName: 'dev-UK',
  timezone: 'Europe/London',
  timeDefinitions: [],
  welcomeScreenEnabled: false,
  hideProductionDevicesData: false,
});
